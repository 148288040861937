import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import DragOutlined from '@ant-design/icons/lib/icons/DragOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import styled from '@emotion/styled';
import Input from 'antd/lib/input';
import { FC, PropsWithChildren, memo } from 'react';

const { TextArea } = Input;

type TBaseProps = {
  order?: number;
  title?: string;
  onDelete?: () => void;
};

interface TActionProps extends TBaseProps {
  mode: 'action';
  onTitleChange: (value?: string) => void;
}

interface TBlockProps extends TBaseProps {
  mode?: 'block';
}

export type TCommonActionProps = TBlockProps | TActionProps;

type TProps = PropsWithChildren<TCommonActionProps>;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 10px;
  margin: 0 15px;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK._400};
`;

const Controls = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: -10px;
`;

const ControlItem = styled('div')<{ cursor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._600};

  svg {
    width: 20px;
    height: auto;
  }
`;

const Title = styled('div')<{ mode: TCommonActionProps['mode'] }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${({ mode }) => (mode === 'action' ? '16px' : '18px')};
  font-weight: ${({ mode }) => (mode === 'action' ? '500' : '600')};
  line-height: 140%;
  color: ${({ theme }) => theme.COLORS.WHITE._100};

  .input {
    height: 22px;
    padding: 0;

    &::-webkit-scrollbar {
      background: transparent;
      width: 6px;
      max-width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-COLORS-BLACK-600);
      border-radius: 3px;
      width: 6px;
      height: 6px;
    }
  }
`;

const Wrapper = styled('div')<{
  order?: number;
  mode: TCommonActionProps['mode'];
}>`
  border-radius: 10px;
  background-color: ${({ theme, mode }) =>
    mode === 'action' ? theme.COLORS.BLACK._300 : theme.COLORS.BLACK._100_00};
  order: ${({ order }) => (order !== undefined ? order : 'unset')};
`;

export const CommonActionBlock: FC<TProps> = memo(
  ({ children, order, title, mode = 'block', onDelete }) => {
    return (
      <Wrapper order={order} mode={mode}>
        <Header>
          <Title mode={mode}>
            {mode === 'action' ? (
              <TextArea
                className="input"
                placeholder="Введите вопрос"
                bordered={false}
                autoSize={{
                  minRows: 1,
                  maxRows: 4,
                }}
              />
            ) : (
              title
            )}
          </Title>
          <Controls>
            {mode !== 'action' && (
              <ControlItem>
                <EditOutlined />
              </ControlItem>
            )}
            <ControlItem onClick={onDelete}>
              <DeleteOutlined />
            </ControlItem>
            <ControlItem cursor="grab">
              <DragOutlined />
            </ControlItem>
          </Controls>
        </Header>
        {children}
      </Wrapper>
    );
  }
);
