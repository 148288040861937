import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import styled from '@emotion/styled';
import { FC, useCallback, useState } from 'react';

import { CommonActionBlock, TCommonActionProps } from '../common-action-block';
import { ESelectType, QuestionItem, TVariant } from './question-item';

type TProps = {} & TCommonActionProps;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 0;
`;

const NewQuestionButton = styled('button')`
  display: flex;
  border: none;
  margin: 15px auto;
  cursor: pointer;
  font-size: 50px;
  background-color: transparent;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

type TQuestion = {
  title?: string;
  mode: ESelectType;
  variants: TVariant[];
  questionId: string;
  order?: number;
};

export const TestActionBlock: FC<TProps> = ({ title, order }) => {
  const [questions, setQuestions] = useState<TQuestion[]>([]);

  const handleQuestionChange = useCallback(
    (id: string, field: keyof TQuestion, value: TQuestion[keyof TQuestion]) => {
      setQuestions((prev) =>
        prev.map((el) => {
          if (el.questionId === id) {
            if (field === 'mode') {
              return {
                ...el,
                variants: el.variants.map((variant) => ({
                  ...variant,
                  correct: false,
                })),
                mode: value as ESelectType,
              };
            }

            return {
              ...el,
              [field]: value,
            };
          }

          return el;
        })
      );
    },
    []
  );

  const handleCreateQuestion = useCallback(() => {
    setQuestions((prev) => [
      ...prev,
      {
        mode: ESelectType.radio,
        variants: [],
        questionId: new Date().toString() + prev.length.toString(),
        order: prev.length,
      },
    ]);
  }, []);

  const handleDeleteVariant = useCallback((id: string) => {
    setQuestions((prev) => prev.filter((el) => el.questionId !== id));
  }, []);

  return (
    <CommonActionBlock title={title} order={order}>
      {questions.length > 0 && (
        <Wrapper>
          {questions.map((el) => (
            <QuestionItem
              key={el.questionId}
              mode={el.mode}
              questionId={el.questionId}
              order={el.order}
              variants={el.variants}
              title={el.title}
              onTitleChange={(value) => {
                handleQuestionChange(el.questionId, 'title', value);
              }}
              setMode={(mode) => {
                handleQuestionChange(el.questionId, 'mode', mode);
              }}
              setVariants={(variants) => {
                handleQuestionChange(
                  el.questionId,
                  'variants',
                  variants(el.variants)
                );
              }}
              onDelete={() => {
                handleDeleteVariant(el.questionId);
              }}
            />
          ))}
        </Wrapper>
      )}
      <NewQuestionButton onClick={handleCreateQuestion}>
        <PlusOutlined />
      </NewQuestionButton>
    </CommonActionBlock>
  );
};
