import styled from '@emotion/styled';
import Input, { InputRef } from 'antd/lib/input';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { boolToText, textToBool } from '../../../utils/common';
import { CommonActionBlock, TCommonActionProps } from './common-action-block';

type TProps = {
  buttonText?: string;
  setButtonText: (value?: string) => void;
  color: string;
  setColor: (value: string) => void;
} & TCommonActionProps;

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
  padding: 15px;
  overflow-x: hidden;
`;

const ColorItem = styled('div')<{ color: string; active: 'true' | 'false' }>`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  transition: background-color 0.3s ease-out;
  background-color: ${({ theme, active }) =>
    textToBool(active) ? theme.COLORS.BLACK._300 : 'transparent'};

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
  }

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.COLORS.BLACK._200 : theme.COLORS.BLACK._300};
  }
`;

const Button = styled('button')<{ color: string }>`
  border: none;
  width: fit-content;
  padding: 6px 16px;
  border-radius: 10px;
  background-color: ${({ color }) => color};
  transition: background-color 0.3s ease-out;
  cursor: text;
  display: block;
  height: 30px;

  .input {
    width: auto;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
    color: ${({ theme }) => theme.COLORS.BLACK._100};
    padding: 0;

    &::placeholder {
      font-size: 12px;
      font-weight: 600;
      line-height: 140%;
      color: ${({ theme }) => theme.COLORS.BLACK._300};
    }
  }
`;

const ColorsList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  max-width: 160px;
`;

const PLACEHOLDER = 'Текст кнопки';

export const ACTION_BUTTON_AVAILABLE_COLORS = [
  '#39D5C9',
  '#3981D5',
  '#6239D5',
  '#B639D5',
  '#45D539',
  '#F6D62E',
  '#D57139',
  '#D53939',
];

export const ButtonActionBlock: FC<TProps> = ({
  title,
  order,
  buttonText,
  setButtonText,
  setColor,
  color,
}) => {
  const inputRef = useRef<InputRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [availableWidth, setAvailableWidth] = useState(999);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setButtonText(e.target.value);
  }, []);

  const inputStyleProp = useMemo(
    () => ({
      width: `${Math.floor((buttonText?.length || PLACEHOLDER.length) * 7)}px`,
      maxWidth: availableWidth,
    }),
    [buttonText, availableWidth]
  );

  useEffect(() => {
    if (wrapperRef.current?.parentElement?.clientWidth) {
      setAvailableWidth(wrapperRef.current?.parentElement?.clientWidth - 62);
    }
  }, []);

  const handleButtonClick = useCallback(() => {
    if (inputRef.current && inputRef.current.input) {
      inputRef.current.input.focus();
    }
  }, []);

  return (
    <CommonActionBlock title={title} order={order}>
      <Wrapper ref={wrapperRef}>
        <Button color={color} onClick={handleButtonClick}>
          <Input
            bordered={false}
            className="input"
            placeholder={PLACEHOLDER}
            style={inputStyleProp}
            value={buttonText}
            onChange={onChange}
            ref={inputRef}
          />
        </Button>
        <ColorsList>
          {ACTION_BUTTON_AVAILABLE_COLORS.map((el) => (
            <ColorItem
              key={el}
              color={el}
              active={boolToText(color === el)}
              onClick={() => {
                setColor(el);
              }}
            />
          ))}
        </ColorsList>
      </Wrapper>
    </CommonActionBlock>
  );
};
