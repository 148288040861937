import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Resource } from 'i18next/typescript/options';
import { initReactI18next } from 'react-i18next';

import { IS_DEV_MODE, RU_LANG_SUPPORT } from '../constants';
// Account locale
import accountEn from './account/en.json';
import accountRu from './account/ru.json';
// Analytics locale
import analyticsEn from './analytics/en.json';
import analyticsRu from './analytics/ru.json';
// Login locale
import loginEn from './login/en.json';
import loginRu from './login/ru.json';
// Menu locale
import menuEn from './menu/en.json';
import menuRu from './menu/ru.json';
// Modal locale
import modalEn from './modal/en.json';
import modalRu from './modal/ru.json';
// Video locale
import videoEn from './video/en.json';
import videoRu from './video/ru.json';

export enum LocaleKeys {
  LOGIN = 'login',
  ACCOUNT = 'account',
  MODAL = 'modal',
  MENU = 'menu',
  VIDEO = 'video',
  ANALYTICS = 'analytics',
}

let resources: Resource = {
  en: {
    [LocaleKeys.LOGIN]: loginEn,
    [LocaleKeys.ACCOUNT]: accountEn,
    [LocaleKeys.MODAL]: modalEn,
    [LocaleKeys.MENU]: menuEn,
    [LocaleKeys.VIDEO]: videoEn,
    [LocaleKeys.ANALYTICS]: analyticsEn,
  },
};

if (RU_LANG_SUPPORT) {
  resources = {
    ...resources,
    ru: {
      [LocaleKeys.LOGIN]: loginRu,
      [LocaleKeys.ACCOUNT]: accountRu,
      [LocaleKeys.MODAL]: modalRu,
      [LocaleKeys.MENU]: menuRu,
      [LocaleKeys.VIDEO]: videoRu,
      [LocaleKeys.ANALYTICS]: analyticsRu,
    },
  };
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: RU_LANG_SUPPORT ? 'ru' : 'en',
    debug: IS_DEV_MODE,
    resources,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {});

export default i18n;
