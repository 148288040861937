import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import styled from '@emotion/styled';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Input from 'antd/lib/input';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Select, { SelectProps } from 'antd/lib/select';
import { ChangeEvent, FC, useCallback } from 'react';

import { CommonActionBlock, TCommonActionProps } from '../common-action-block';

const { TextArea } = Input;

const Wrapper = styled('div')`
  padding: 10px 15px 20px 15px;
  width: 100%;

  .select {
    margin-bottom: 8px;
  }

  .ant-select-arrow {
    width: 11px;
    height: 7px;
    margin-top: -3px;
    background-size: contain;
    inset-inline-end: 6px;
  }
`;

const Variant = styled('div')`
  display: flex;

  .radio-item,
  .check-item {
    margin-top: 4px;
    margin-right: 0;
  }

  .input {
    &::-webkit-scrollbar {
      background: transparent;
      width: 6px;
      max-width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-COLORS-BLACK-600);
      border-radius: 3px;
      width: 6px;
      height: 6px;
    }
  }

  .btn-delete {
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }

  &:hover {
    .btn-delete {
      opacity: 1;
    }
  }
`;

const AddVariantButton = styled('button')`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.COLORS.BLACK._600};
  gap: 10px;
  cursor: pointer;
  margin-top: 10px;
`;

export enum ESelectType {
  radio = 'radio',
  checkbox = 'checkbox',
}

const SELECT_OPTIONS: SelectProps['options'] = [
  {
    value: ESelectType.radio,
    label: 'Один из списка',
  },
  {
    value: ESelectType.checkbox,
    label: 'Несколько из списка',
  },
];

export type TVariant = {
  label?: string;
  value: string;
  correct: boolean;
};

type TSetVariants = (param: (variants: TVariant[]) => TVariant[]) => void;

type TProps = {
  title?: string;
  onTitleChange: (value?: string) => void;
  mode: ESelectType;
  setMode: (mode: ESelectType) => void;
  variants: TVariant[];
  setVariants: TSetVariants;
  questionId: string;
} & Omit<TCommonActionProps, 'mode' | 'title'>;

const VariantItem: FC<
  {
    onChange: TProps['setVariants'];
    mode: ESelectType;
    questionId: string;
  } & TVariant
> = ({ label, value, onChange, mode, correct, questionId }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange((prev) => {
        return prev.map((el) => {
          if (el.value === value) {
            return {
              ...el,
              label: e.target.value,
            };
          }

          return el;
        });
      });
    },
    [value, onChange]
  );

  const handleDelete = useCallback(() => {
    onChange((prev) => prev.filter((el) => el.value !== value));
  }, [onChange, value]);

  const toggleCorrect = useCallback(() => {
    onChange((prev) => {
      return prev.map((el) => {
        if (el.value === value) {
          return {
            ...el,
            correct: !el.correct,
          };
        }
        return el;
      });
    });
  }, [value, onChange]);

  const onChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      onChange((prev) => {
        if (e.target.checked) {
          return prev.map((el) => {
            if (el.value === value) {
              return {
                ...el,
                correct: true,
              };
            }
            return {
              ...el,
              correct: false,
            };
          });
        } else return prev;
      });
    },
    [onChange, value]
  );

  return (
    <Variant>
      <div>
        {mode === ESelectType.radio ? (
          <Radio
            className="radio-item"
            name={questionId}
            checked={correct}
            onChange={onChangeRadio}
          />
        ) : (
          <Checkbox
            className="check-item"
            name={questionId}
            checked={correct}
            onClick={toggleCorrect}
          />
        )}
      </div>
      <TextArea
        className="input"
        placeholder="Вариант"
        value={label}
        onChange={handleChange}
        autoSize={{
          minRows: 1,
          maxRows: 3,
        }}
        bordered={false}
      />
      <Button
        size={'small'}
        danger
        className="btn-delete"
        onClick={handleDelete}
      >
        <DeleteOutlined />
      </Button>
    </Variant>
  );
};

export const QuestionItem: FC<TProps> = ({
  order,
  setMode,
  mode,
  setVariants,
  variants,
  title,
  onTitleChange,
  questionId,
  onDelete,
}) => {
  const handleNewVariant = useCallback(() => {
    setVariants((prev) => {
      return [
        ...prev,
        {
          value: new Date().toString() + prev.length.toString(),
          correct: false,
        },
      ];
    });
  }, [setVariants]);

  return (
    <CommonActionBlock
      title={title}
      order={order}
      mode="action"
      onTitleChange={onTitleChange}
      onDelete={onDelete}
    >
      <Wrapper>
        <Select
          defaultValue={mode}
          style={{ width: '100%' }}
          options={SELECT_OPTIONS}
          onChange={setMode}
          size="small"
          className="select"
        />
        <div>
          {variants.map((variant) => (
            <VariantItem
              key={variant.value}
              onChange={setVariants}
              mode={mode}
              questionId={questionId}
              {...variant}
            />
          ))}
        </div>
        <AddVariantButton onClick={handleNewVariant}>
          <PlusOutlined />
          Добавить вариант
        </AddVariantButton>
      </Wrapper>
    </CommonActionBlock>
  );
};
