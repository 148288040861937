import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import styled from '@emotion/styled';
import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useState,
} from 'react';

import { ActionsHeader } from './actions-blocks/actions-header';
import {
  ACTION_BUTTON_AVAILABLE_COLORS,
  ButtonActionBlock,
} from './actions-blocks/button-action-block';
import { TestActionBlock } from './actions-blocks/test-action-block';
import { TextActionBlock } from './actions-blocks/text-action-block';
import { VideoPauseActionBlock } from './actions-blocks/video-pause-action-block';

const Button = styled('button')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  font-size: 25px;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.COLORS.BLACK._100};
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  border-radius: 20px;
  padding: 15px 30px;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.ACCENT._400};
  }

  .ico {
    font-size: 38px;
    width: 38px;
    height: auto;
  }
`;

const ActionsWrapper = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  border-radius: 20px;
  margin-top: 20px;
  padding: 20px;
`;

const List = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

type TProps = {};
type TRef = HTMLVideoElement | null;

export const ActionsEdit: ForwardRefExoticComponent<RefAttributes<TRef>> =
  forwardRef<TRef, TProps>(({}, ref) => {
    const [title, setTitle] = useState<string | undefined>();
    const [text, setText] = useState<string | undefined>();

    const [buttonText, setButtonText] = useState<string | undefined>();
    const [buttonColor, setButtonColor] = useState<string>(
      ACTION_BUTTON_AVAILABLE_COLORS[0]
    );

    const [videoPause, setVideoPause] = useState<number | undefined>(10);

    return (
      <div>
        <Button>
          Добавить действие
          <PlusOutlined className="ico" />
        </Button>
        <ActionsWrapper>
          <ActionsHeader ref={ref} />
          <List>
            <TextActionBlock
              title="Заголовок"
              inputMode="input"
              value={title}
              onChange={setTitle}
            />
            <TextActionBlock
              title="Блок текста"
              inputMode="textarea"
              value={text}
              onChange={setText}
            />
            <ButtonActionBlock
              title="Кнопка"
              buttonText={buttonText}
              setButtonText={setButtonText}
              color={buttonColor}
              setColor={setButtonColor}
            />
            <VideoPauseActionBlock
              title="Остановить видео"
              videoPause={videoPause}
              setVideoPause={setVideoPause}
            />
            <TestActionBlock title="Тестирование" />
          </List>
        </ActionsWrapper>
      </div>
    );
  });
