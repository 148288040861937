import styled from '@emotion/styled';
import Input from 'antd/lib/input';
import { ChangeEvent, FC, memo, useMemo } from 'react';

import { CommonActionBlock, TCommonActionProps } from './common-action-block';

const { TextArea } = Input;

const Wrapper = styled('div')`
  padding-block: 10px;
  padding-right: 15px;

  .ant-input {
    padding-left: 15px;
    padding-right: 0;

    &::-webkit-scrollbar {
      background: transparent;
      width: 6px;
      max-width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-COLORS-BLACK-600);
      border-radius: 3px;
      width: 6px;
      height: 6px;
    }
  }
`;

type TProps = {
  inputMode: 'input' | 'textarea';
  value: string | undefined;
  onChange: (value: string | undefined) => void;
} & TCommonActionProps;

export const TextActionBlock: FC<TProps> = memo(
  ({ title, order, value, onChange, inputMode }) => {
    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onChange(e.target.value);
    };

    const InputComponent = useMemo(() => {
      return inputMode === 'input' ? Input : TextArea;
    }, []);

    return (
      <CommonActionBlock title={title} order={order}>
        <Wrapper>
          <InputComponent
            placeholder="Введите текст"
            bordered={false}
            value={value}
            onChange={handleChange}
            {...(inputMode === 'textarea'
              ? {
                  autoSize: {
                    minRows: 4,
                    maxRows: 8,
                  },
                }
              : {})}
          />
        </Wrapper>
      </CommonActionBlock>
    );
  }
);
