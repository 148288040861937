import styled from '@emotion/styled';
import { MutableRefObject, forwardRef, useEffect, useState } from 'react';

import { timeToString } from '../../../utils/common';

type TProps = {};

type TRef = HTMLVideoElement | null;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
`;

const Title = styled('span')`
  text-align: center;
  color: ${({ theme }) => theme.COLORS.WHITE._400};
`;

const TimeMarker = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  height: 40px;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  color: ${({ theme }) => theme.COLORS.BLACK._100_00};
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`;

export const ActionsHeader = forwardRef<TRef, TProps>(({}, videoRef) => {
  const [videoTime, setVideoTime] = useState<string>('00:00');

  useEffect(() => {
    const video = videoRef as MutableRefObject<TRef>;

    const listener = (e: Event) => {
      setVideoTime(
        timeToString(Math.round((e.target as HTMLVideoElement).currentTime))
      );
    };

    if (video.current) {
      video.current.addEventListener('timeupdate', listener);
    }

    return () => {
      if (video.current) {
        video.current.removeEventListener('timeupdate', listener);
      }
    };
  }, []);

  return (
    <Wrapper>
      <Title>Действия на шкале в это время</Title>
      <TimeMarker>{videoTime}</TimeMarker>
    </Wrapper>
  );
});
