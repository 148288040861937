import styled from '@emotion/styled';
import { FC, useMemo, useRef } from 'react';

import { PageBackButton } from '../components/common/page-back-button';
import { ActionsEdit } from '../components/video-actions/actions-edit';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { useVideoPathId } from '../hooks/use-video-path-id';
import mockVideo from '../mocks/mock-video.mp4';
import { mockVideoList } from '../mocks/uploaded';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 40px;
  margin-top: 80px;
  padding-right: 40px;
  padding-bottom: 60px;
`;

const ScrollContainer = styled('div')`
  position: relative;
`;

const VideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

export const VideoActions: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const id = useVideoPathId();

  const fondedVideo = useMemo(() => {
    return mockVideoList.find((el) => el.id === id) ?? mockVideoList[0];
  }, [id]);

  return (
    <div>
      <PageBackButton text={fondedVideo.name} />
      <Wrapper>
        <ScrollContainer>
          <VideoWrapper>
            <VideoPlayer
              videoSrc={mockVideo}
              ref={videoRef}
              preview={
                fondedVideo
                  ? {
                      url: fondedVideo.previewUrl,
                      alt: `Превью видео: ${fondedVideo.name}`,
                    }
                  : undefined
              }
            />
            <TimeLineControl ref={videoRef} zeroPaddings={true} />
          </VideoWrapper>
        </ScrollContainer>
        <ActionsEdit ref={videoRef} />
      </Wrapper>
    </div>
  );
};
