import styled from '@emotion/styled';
import Slider, { SliderSingleProps } from 'antd/lib/slider';
import { FC } from 'react';

import { boolToText, textToBool } from '../../../utils/common';
import { CommonActionBlock, TCommonActionProps } from './common-action-block';

type TProps = {
  videoPause?: number;
  setVideoPause: (value?: number) => void;
} & TCommonActionProps;

const SelectItem = styled('button')<{ active: 'true' | 'false' }>`
  border: none;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  color: ${({ theme, active }) =>
    textToBool(active) ? theme.COLORS.ACCENT._200 : theme.COLORS.BLACK._700};
  background-color: ${({ theme, active }) =>
    textToBool(active) ? theme.COLORS.BLACK._300 : 'transparent'};
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out;

  &:hover {
    background-color: ${({ theme, active }) =>
      textToBool(active) ? theme.COLORS.BLACK._300 : theme.COLORS.BLACK._200};
  }

  .value {
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 8px;
  }

  .cur {
    display: block;
    text-align: center;
    margin-top: -5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
  }
`;

const SlideWrapper = styled('div')`
  height: 60px;
  display: flex;
  align-items: center;
  flex: 1;

  .ant-slider {
    width: 100%;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 25px 15px;
`;

const SLIDER_PROPS: SliderSingleProps = {
  min: 0,
  max: 100,
  tooltip: {
    placement: 'bottom',
  },
  marks: {
    0: '0',
    100: '100',
  },
};

export const VideoPauseActionBlock: FC<TProps> = ({
  title,
  order,
  videoPause,
  setVideoPause,
}) => {
  return (
    <CommonActionBlock title={title} order={order}>
      <Wrapper>
        <SelectItem
          active={boolToText(videoPause === 5)}
          onClick={() => {
            setVideoPause(5);
          }}
        >
          <div className="value">5</div>
          <div className="cur">сек</div>
        </SelectItem>
        <SelectItem
          active={boolToText(videoPause === 10)}
          onClick={() => {
            setVideoPause(10);
          }}
        >
          <div className="value">10</div>
          <div className="cur">сек</div>
        </SelectItem>
        <SlideWrapper>
          <Slider
            value={videoPause}
            onChange={setVideoPause}
            {...SLIDER_PROPS}
          />
        </SlideWrapper>
      </Wrapper>
    </CommonActionBlock>
  );
};
